.export-modal {
  --backdrop-opacity: 0.8;
  --height: auto;
  --border-radius: 10px;

  &::part(content) {
    --border-style: none;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Use soft border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  }

  .export-header {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }

  ion-grid {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .ion-page {
    padding: 20px;
    border: 0px !important;

  }

  .export-crate-name {
    width: 550px;
  }

  .export-option-row {
    padding: 5px;
    justify-content: center;
    align-items: center;
    margin-right: 20%;
  }

  .export-option-col {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .export-option-toggle {
    padding-right: 20px;
    padding-left: 10px;
  }

  .ion-align-items-center {
    display: flex;
    align-items: center;
  }

  .ion-text-right {
    text-align: right;
  }

  .ion-justify-content-center {
    justify-content: center;
  }

  .select-directory-button {
    --border-width: 1px;
    --background-hover: var(--ion-color-primary);
    --background-hover-opacity: 20%;
  }
}

.popover-link {
  color: var(--ion-color-primary-tint);
  cursor: pointer;
}

.export-method-label {
  padding-right: 6px;
}
