.dropdown-container {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  flex-wrap: wrap;
  max-height: 16em;
}

.resource-name-text {
  --inner-border-width: 0;

  &:hover {
    cursor: pointer;
  }
}
