#pools-container {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.track-name-text {
  --inner-border-width: 0;
  font-size: 1em;
}

.pool-name-text {
  --inner-border-width: 0;
}

.pool-name-text:hover, .track-name-text:hover {
  cursor: pointer;
}
