.condensed-track-view {
  display: flex;
  align-items: center;
  width: 97%;
  background-color: rgba(237, 130, 22, 0.09);
  border-radius: 12px;
  padding: 3px;
  margin: 5px;
  text-align: center;
}

.track-matcher-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.15s ease-in-out;
}

.track-matcher-container.open {
  max-height: 100%;
}

.unmixable {
  background-color: rgba(211, 119, 28, 0.2);
}

.condensed-track-view:hover {
  cursor: pointer;
}

.ion-col-condensed {
  padding: 0 !important; /* Remove padding inside IonCol */
  margin: 0 !important; /* Remove any extra margin */
  max-width: 50px !important; /* Set fixed width to match the image */
  flex: 0 0 50px !important; /* Ensure the column takes up the exact width */
}

.condensed-track-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 50px; /* Set a max width */
  max-height: 50px; /* Set a max height */
}

.condensed-track-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.condensed-track-title,
.condensed-track-artist {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.condensed-track-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 4px;
}

.condensed-track-artist {
  font-size: 0.9rem;
  color: #888;
}

.single-track-view {
  .track-view-artist {
    font-size: 1.9rem;
    line-height: 1.9rem;
    color: #888;
    margin-left: 15px;
  }

  .track-view-title {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 20px;
    margin-left: 15px;
  }

  .track-view-bpm,
  .track-view-key {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-left: 15px;
    margin-top: 20px;
  }

  .audio-feature-wrapper {
    margin-top: 2vw;
  }
}

.track-delete {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: 10px;
}

.no-track-matches {
  margin-left: 20px;
  font-size: 16px;
  padding: 20px;

  ion-icon {
    color: var(--ion-color-danger);
    margin-right: 2px;
    vertical-align: bottom;
    height: 18px;
  }
}

// Ensures that native browser text highlighting is disabled during drag-n-drop
.no-highlight {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
