ion-header {
  ion-toolbar {
    height: var(--header-height);
    padding: 10px !important;
  }

  .logo {
    height: 70px;
    width: 75px;
    margin: 0 auto;
    background-size: 75px;
    background-repeat: no-repeat;
  }

  .center {
    max-width: 400px;
    margin: 0 auto;

    .searchbar-input {
      border-radius: 25px;
    }
  }

  .navigationButton {
    height: 32px;
    cursor: pointer;
    margin-left: 6px;
    width: 32px;
  }
}



.signout-button {
  line-height: initial;
}
