.folder-grid {
  height: 82%;
  margin-top: 32px;

  .ag-row {
    .ag-cell {
      line-height: 60px;
    }
  }

  .ion-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .ion-icon {
      height: 100%;
      width: 45%;
      cursor: pointer;
    }
  }
}

.add-folder-popover {
  --width: 175px;
}
.dark .add-folder-popover {
  --ion-background-color: #222222;
}
