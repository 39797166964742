.track-results {
  margin-top: 20px;
  max-height: 386px;
  overflow-y: scroll;
  padding: 0;
}

.result-item {
  cursor: pointer;
  position: relative;
  border: 1px solid #e57d2b;
  padding: 6px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  margin: 10px 0;
  align-items: flex-start;
  user-select: none;

  div {
    font-size: 0.97rem;
  }

  img {
    height: 70px;
    margin-right: 10px;
  }

  .result-label {
    margin-top: 10px;

    .title {
      font-size: 1.35em;
    }
    .artist {
      margin-top: 12px;
      font-size: 1.1em;
    }
  }
}

.result-selected {
  position: absolute;
  right: 20px;
  top: 25px;
}

.result-item-active {
  background-color: #a45b14;
}
