.djep-path {
  min-height: 600px;
  max-height: 62%;
  overflow-y: scroll;

  font-size: 18px;
  color: white;
  user-select: none;
  padding: 0;

  .tree,
  .tree-node-group {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .tree {
    height: 400px;
    overflow-y: scroll;

    &li {
      margin-top: 2px;
    }
  }

  .tree-node {
    padding: 4px 0;
  }

  .tree-branch-wrapper,
  .tree-node__leaf {
    outline: none;
  }

  .tree-node {
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  .tree {
    .tree-node--focused {
      background: rgba(255, 255, 255, 0.2);
    }

    .tree-node--selected {
      background: #ca691d;
    }
  }

  .tree-node__branch {
    display: block;
  }

  ion-icon {
    vertical-align: top;
    padding-right: 5px;
  }
}