.context-menu {
  user-select: none;
  width: 175px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      //border: 1px solid #b3b3b3;
      padding: 4px 10px;
      margin: 0;
      line-height: 32px;
      font-size: 14px;

      &:hover {
        background: rgba(33, 150, 243, 0.1);
        cursor: pointer;
      }

      ion-icon,
      svg {
        margin-right: 5px;
      }
    }
  }
}
