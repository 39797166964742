.key-PerfectMatch {
  color: green;
}

.key-EnergyBoost {
  color: var(--ion-color-primary);
}

.key-EnergyDrop {
  color: #7979ff;
}

.key-MoodChange {
  color: var(--ion-color-secondary);
}

.key {
  display: flex;
  flex-direction: row;
}