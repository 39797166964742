.full-width {
  width: 100%;
}

.generate-crate-ctn {
  display: flex;

  width: 94%;
  position: absolute;
  bottom: 15px;

  ion-button {
    --color: #fff;
  }
}

.generate-crate-header {
  padding-top: 18px;
}

.generate-crate-header > div {
  font-size: 1.2rem;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.generate-crate-btn {
  height: 28px;
  border-radius: 8%;
  background: linear-gradient(#e57d2b, #f89e23);
  color: #fff;

  display: flex;
  padding: 10px 0 10px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  /* Text sm/Medium */
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  user-select: none;

  &.disabled {
    background: #ccc; // change to any color you like for the disabled state
    cursor: not-allowed; // change the cursor style when the button is disabled
    opacity: 0.5; // lower the opacity to indicate "disabled" state
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;

    &.disabled {
      cursor: auto;
      opacity: 0.5; // ensure hover doesn't change disabled button appearance
    }
  }
}
.build-pane-wrapper {
}
