ion-menu {
  --side-width: var(--sidebar-width);
  --side-max-width: var(--sidebar-width);
  --side-min-width: var(--sidebar-width);
  --ion-background-color: var(--ion-menu-background);
  height: 100%;
  z-index: 9999;

  .footer-items {
    min-height: 5em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 10px;
  }

  ion-content {
    margin-bottom: 200px;
  }

  ion-item {
    --min-height: unset !important;
  }
}

ion-menu.md {
  ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }

  ion-list {
    ion-item.external-link:hover {
      cursor: pointer;
      background-color: #1c1c1d;
    }

    &#menu-list {
      border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);

      ion-item.active {
        background-color: #1c1c1d;
      }

      #spotify-import {
        margin-top: 60px;
      }

      ion-list-header {
        font-size: 22px;
        font-weight: 600;
        min-height: 20px;
      }

      ion-list-header {
        font-size: 16px;
        margin-bottom: 18px;
        color: #757575;
        min-height: 26px;
      }
    }
  }

  ion-note {
    margin-bottom: 30px;
  }

  ion-list-header,
  ion-note {
    padding-left: 10px;
  }

  ion-item {
    --padding-start: 5px;
    --padding-end: 5px;
    border-radius: 4px;
    font-size: 14px;
    --ion-color-base: transparent !important;

    .selected {
      --background: rgba(var(--ion-color-primary-rgb), 0.14);

      ion-icon {
        color: var(--ion-color-primary);
      }
    }

    ion-icon {
      color: #fff;
      margin-inline-end: 16px;
    }

    ion-button ion-icon {
      margin-inline-end: 0 !important;
    }

    ion-label {
      font-weight: 500;
    }
  }
}

ion-menu.ios {
  ion-content {
    --padding-bottom: 20px;
  }

  ion-list {
    padding: 20px 0 0 0;

    &#labels-list {
      ion-list-header {
        margin-bottom: 8px;
      }
    }
  }

  ion-note {
    line-height: 24px;
    margin-bottom: 8px;
  }

  ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;

    ion-icon {
      font-size: 24px;
      color: #73849a;
    }

    .selected {
      ion-icon {
        color: var(--ion-color-primary);
      }
    }
  }

  ion-list-header,
  ion-note {
    padding-left: 16px;
    padding-right: 16px;
  }
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);

  &.selected {
    --color: var(--ion-color-primary);
  }
}

ion-list ion-item.external-link {
  margin-right: 8px;
}
