.feature-in-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.feature-in-progress-card {
  width: 80%;
  max-width: 500px;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  ion-icon {
    color: #d3771d;
  }
}
