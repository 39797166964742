@use 'breakpoints' as breakpoints;

.filter-slider-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.2em;
}

.filter-toggle::part(label) {
  margin-bottom: 0.25rem;
}

.filter-slider-row {
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;

  > * {
    width: 100%;
  }

  &-active {
    composes: filter-slider-row;
    height: var(--crate-filter-height);
    max-height: var(--crate-filter-height);
    position: fixed;
    top: 0;
    left: 0;
    background: var(--ion-menu-background);
    z-index: 3;
    border-bottom: 1px solid var(--ion-border-color);
  }
}

.filter-range-row {
  align-items: center;
  justify-content: space-between;
  column-gap: 3%;
  max-width: breakpoints.$xl-breakpoint;
}

.filter-range {
  padding-top: 0;

  &::part(label) {
    margin-bottom: 0;
    text-align: center;
    transform: none;
  }
}

.hidden-track-button {
  position: absolute;
  margin: 0;
  top: 0.25rem;
  left: -0.3em; // This is to account for the negative margin already attached to the icon in the button provided by Ionic. This allows for horizontal centering with the checkbox above
  text-decoration: underline;
  white-space: nowrap;
}

@media (max-width: breakpoints.$medium-breakpoint) {
  .filter-slider-row {
    padding: 0 1rem;
    align-items: flex-start;
  }

  .hidden-track-button {
    width: 100vw;
    top: 0.5rem;
    left: -1rem;
  }
}
