.spotifyIcon {
  color: black;
  height: 42px;
  margin-right: 10px;
}

.spotifyLoginButton {
  color: #1ED760;
  --border-color: #1ED760;
  --border-width: 1px;
  --background-hover: #1ED760;
  --background-hover-opacity: 20%;

}

.small {
  .spotifyIcon {
    color: black;
    height: 19px;
    margin-right: 10px;
  }
}