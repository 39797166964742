@use '~ag-grid-community/styles' as ag;

@font-face {
  font-family: 'Inter';
  src: url('../public/assets/fonts/Inter-Regular.ttf') format('truetype');
  font-style: normal;
}

body {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

h1 {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 142.857% */
}

.ag-theme-alpine-dark {
  --ag-background-color: #171717;
  --ag-foreground-color: #fff;
  --ag-border-color: #232323;
  --ag-secondary-border-color: rgba(88, 86, 82, 0.5);
  --ag-modal-overlay-background-color: rgba(24, 29, 31, 0.66);
  --ag-header-background-color: #171717;
  --ag-tooltip-background-color: #171717;
  --ag-odd-row-background-color: #171717;
  --ag-control-panel-background-color: #171717;
  --ag-subheader-background-color: #000;
  --ag-input-disabled-background-color: #282c2f;
  --ag-input-focus-box-shadow: 0 0 2px 0.5px rgba(255, 255, 255, 0.5),
  0 0 4px 3px var(--ag-input-focus-border-color);
  --ag-card-shadow: 0 1px 20px 1px black;
  --ag-disabled-foreground-color: rgba(255, 255, 255, 0.5);
  --ag-chip-background-color: rgba(255, 255, 255, 0.07);
  --ag-input-disabled-border-color: rgba(104, 104, 110, 0.3);
  --ag-input-disabled-background-color: rgba(104, 104, 110, 0.07);
  --ag-row-hover-color: #232323;
  --ag-selected-row-background-color: #232323;
  --ag-range-selection-border-color: #232323;
}

:root {
  --header-height: 80px;
  --footer-height: 0; // Footer is currently unused/empty
  --sidebar-width: 240px;
}

ion-router-link {
  --color: #d3771d;
  line-height: 2rem;
  word-break: break-word;
}

ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-bottom: 20px;
  --padding-top: 20px;
}

ion-input {
  --placeholder-color: var(--ion-color-placeholder);
  --color: var(--ion-color-input);
}

ion-split-pane {
  margin-top: var(--header-height);
  margin-bottom: var(--footer-height);
}

#main {
  padding: 20px;
}

body {
  min-height: 100vh;
}

.link-label {
  display: inline-block;
  margin-bottom: 8px !important;
}

button.primary.btn-transparent {
  background: transparent;
  border: none;
}

.page-header {
  display: flex;
  align-items: center; /* This aligns items vertically in the center */
  justify-content: space-between; /* This separates the title and the buttons */
}

.button-group {
  display: flex;
  align-items: center; // This ensures that buttons align perfectly if they have different sizes
  gap: 10px; // Adjust the gap value as needed to get the desired spacing
}

button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  /* Text sm/Medium */
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  &.primary,
  &.alternative {
    border-radius: 8px;

    /* Shadow/xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  &.alternative {
    background-color: var(--ion-color-primary);

    .ion-icon {
      color: #fff;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  .ion-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
}

//.ion-input-wrapper {
.label-text-wrapper [slot='label'] {
  font-size: 16px;
  padding-left: 5px;
}

ion-input {
  background: #160d03;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  flex-grow: 1;
  border: none;
  padding: 6px 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
  --padding-start: 10px !important;
  margin-top: 10px;
}

//}

.group-crates-button {
  margin: 10px;

  &.active {
    background-color: rgba(211, 119, 28, 0.3);
    color: white;
  }
}

.search-box {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  max-width: 372px;

  input[type='text'] {
    flex-grow: 1;
    background: transparent;
    border: none;
    padding: 6px 10px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    &:focus {
      outline: none;
    }
  }

  button {
    background: transparent;
    border: none;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;

    //&:hover {
    //background: rgba(255, 255, 255, 0.1); // Slight highlight on hover
    //}

    .search-icon {
      width: 20px;
      height: 20px;
      display: block;
    }
  }
}

.dark {
  button {
    color: #fff;

    &.primary {
      border: 1px solid #120b02;
      background: #120b02;

      /* Shadow/xs */
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Inter, sans-serif;
}

a {
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
}

.alert-wrapper {
  --background: #1c1c1d;
  border: 1px solid #d3771c;
}

.modal-default {
  --background: #1c1c1d;

  .ion-page {
    border: 2px solid #d3771c;
    height: calc(100% - 44px);
  }
}

.preferences-modal {
  --background: #1c1c1d;

  .ion-page {
    border: 2px solid #d3771c;
    height: calc(100% - 4px);
    padding-left: 100px;
    padding-right: 100px;
    overflow-y: scroll;
  }
}

.modal {
  .ion-page {
    border: 1px solid #d3771c;
    background-color: #1c1c1d;
    height: calc(100% - 2px);
  }

  ion-toolbar {
    --ion-toolbar-background: #d3771c;
    color: #222428;
    margin-bottom: 20px;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  hr {
    background-color: #d3771c;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .links {
    text-align: center;

    a {
      margin: 0 10px;
    }
  }

  h1,
  h2 {
    margin: 0 10px 20px;
  }

  h3,
  h4,
  h5,
  h6 {
    margin: 30px 10px 0;
  }

  h1,
  h2,
  h2 {
    text-align: center;
  }

  p {
    margin: 10px 20px;
  }

  p,
  ul,
  ol,
  dl {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  ul,
  ol,
  dl {
    margin-top: 10px;
  }
}

.ch-toast {
  --ion-color-base: #1c1c1d !important;
  --ion-color-base-rgb: 28, 28, 29 !important;
  --ion-color-contrast: #fff !important;
  --ion-color-contrast-rgb: 255, 255, 255 !important;
  font-family: Inter, sans-serif;
}

.ch-toast.ion-color-success::part(container) {
  border: 1px solid var(--ion-color-success);
}

.ch-toast.ion-color-danger::part(container) {
  border: 1px solid var(--ion-color-danger);
}

.context-menu ul li svg,
.context-menu ul li ion-spinner,
.context-menu ul li ion-icon {
  height: 14px;
  width: 24px;
  margin-right: 6px;
}
