.delimiterAlert {
  --backdrop-opacity: 70%;
}

.text-primary-buttons {
  color: white;
}

#column-mapping {
  --height: 220px;
  --width: 420px;

  .ion-page {
    padding: 20px;
  }

  .button-col {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mapping-button {
    min-width: 150px; // Adjust this value as needed for your design
  }
}
