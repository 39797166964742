.page-header {
  margin-bottom: 20px;
}

.page-header .title {
  font-size: 28px;
  height: 40px;
  font-weight: 600;
  line-height: 40px;
}

.page-header .subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
}

.step-header {
  padding: 12px 25px;
  text-align: center;
}

.flatten-path {
  background: rgba(237, 130, 23, 0.1);
  border-radius: 8px;
  border: 1px solid #552f10;
  min-height: 650px;
  max-height: 100%;
  overflow-y: scroll;
  padding: 20px;
  position: relative;
}

.select-folder {
  min-height: 650px;
  height: calc(100% - 100px);
  margin-bottom: 20px;
  overflow: hidden;

  ion-col {
    padding: 0 5px;
    overflow: hidden;
  }

  .select-folder-list {
    height: calc(100% - 128px);
    overflow-y: scroll;
    padding: 0 20px;
  }

  h1 {
    text-align: center;
    margin-bottom: 10px;
  }

  .page-copy {
    margin-bottom: 20px;
  }

  .select-folder-button {
    margin: 0 auto;
  }

  .path-subtext {
    color: #666;
    font-size: 12px;
  }

  .table-header-container,
  .table-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-header-container {
    border-bottom: 1px solid #c3c3c3;
    padding: 20px;
  }

  .table-header,
  .table-header-files,
  .table-header-size,
  .table-header-actions {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    font-size: 16px;
    font-weight: 800;
  }

  .table-header-files,
  .table-header-size,
  .table-header-actions {
    text-align: center;
  }

  .table-column-size,
  .table-column-files {
    text-align: center;
  }

  .table-column-action {
    display: flex;
    justify-content: space-around; /* Distributes space around the buttons */
    align-items: center; /* Aligns the buttons vertically in the center */
  }

  .remove-button {
    margin: 0 auto;
  }

  .conflict-file-path p {
    cursor: pointer;
    margin-left: 10px;
    color: #888;
  }

  .keep-button,
  .delete-button,
  .rename-button {
    margin: 0 4px;
  }

  .keep-button {
    background: rgba(28, 148, 40, 1) !important;
  }
  .delete-button {
    background: rgba(148, 40, 28, 1) !important;
  }
  .rename-button {
    background: rgba(40, 28, 148, 1) !important;
  }

  @media (max-width: 1349px) {
    .keep-button,
    .delete-button,
    .rename-button {
      ion-label {
        display: none;
      }
    }
  }
}

.action-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  ion-button {
    --color: #fff;
  }

  .align-center {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}

.verify-modal {
  --height: 264px;

  h2 {
    margin: 0 20px 20px 20px;
  }
  p,
  .action-container {
    margin: 20px 0;
  }
  ion-input {
    margin: 20px 0px;
    width: auto;
  }
}

.flatten-grid {
  height: 54vh;
  width: 100%;
}
.grid {
  height: 100%;
}
