body.dark {
  .feed-section {
    .feed-header {
      background-color: rgba(0, 0, 0, 0.52);
    }
  }
}

.feed-section {
  padding: 16px;
  @media (max-width: 767px) {
    .order-first-on-mobile {
      order: -1; /* This will render the component first on mobile screens */
    }
  }
  .feed-header {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    position: sticky;
    top: 0;
    z-index: 100;
    border-radius: 30px;

    background-color: rgba(231, 231, 231, 0.5);

    ion-segment {
      width: 50%;
      border-radius: 8px;
      overflow: hidden;
      --indicator-color: #fff;
      --indicator-height: 3px;
    }
  }
}

.swiper-container {
  position: relative;
}

.swiper-buttons {
  position: absolute;
  width: 100%;
  top: 40%;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    pointer-events: auto;
    --border-radius: 50%;
    --padding-start: 0;
    --padding-end: 0;
    width: 48px;
    height: 48px;
    margin: 0 4px;
  }

  .swiper-button-prev {
    left: -20px;
  }

  .swiper-button-next {
    right: -20px;
  }
}
