.dragOverlay {
  --horizontal-offset: 0px;
  @media (min-width: 1200px) {
    --horizontal-offset: var(--sidebar-width);
  }

  // Accounts for the top-bar and side-bar when present
  // This is necessary because Ionic renders the page in a shadow DOM so it has no knowledge of these components in order to provide the correct offset
  transform: translate(
    calc(var(--horizontal-offset) * -1),
    calc(var(--header-height) * -1)
  );
}
