.title {
  display: flex;
  gap: 0.5rem;
  margin: 0;
}

.hidden-tracks {
  padding: 1rem 0;
  scroll-margin-top: var(--crate-filter-height);

  ion-item::part(native) {
    --padding-start: 0;
    --ripple-color: transparent;

    width: auto;
  }
}
