.slide-yt {
  border-radius: 12px;
  margin: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.slide-yt:hover {
  transform: scale(1.05);
}
.slide-yt img {
  width: 100%;
  border-radius: 12px;
}
