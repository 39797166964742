.pool-selection-item {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pool-checkbox-label {
  padding-left: 5px;
}

#pool-selection-buttons {
  padding-left: 25px;
  width: 300px;
  justify-content: space-between;
  display: flex;
}
