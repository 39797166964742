.build-path {
  background: rgba(237, 130, 23, 0.1);
  border-radius: 8px;
  border: 1px solid #552f10;
  height: 650px;
  padding: 25px;
  position: relative;
}

.buildHeader {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.build-input {
  --background: #170f04;
}

.loginContainer {
  border: 1px solid #d3771d;
  border-radius: 5px;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  margin: 0 0 40px;
  font-size: 16px;
}

.linkContainer {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.sideBySide {
  display: flex;
  align-items: center;
  gap: 20px; // Add space between items
}

.noticeIcon {
  color: #d3771d;
  font-size: 48px;
}
