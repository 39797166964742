.result-item {
  position: relative;
  //border: 1px solid #e57d2b;
  padding: 6px;
  //border-radius: 5px;
  color: #fff;
  display: flex;
  margin: 20px 20px;
  align-items: flex-start;
}

.result-item div {
  font-size: 1.5rem;
  line-height: 2rem;
}
.result-item small {
  font-size: 1rem;
}

.result-item img {
  height: 125px;
  margin-right: 20px;
}

.result-selected {
  position: absolute;
  right: 10px;
  top: 8px;
}

.result-item-active {
  background-color: #a45b14;
}
