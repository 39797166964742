.track {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--ion-color-light);
  padding-top: 20px;

  &.first {
    border-top: 1px solid var(--ion-color-light);
  }

  &.disabled {
    padding: 0;
    opacity: 0.7;
    border-bottom: none;

    .track-circle {
      display: none;
    }

    h1 {
      font-size: 14px;
      line-height: normal;
      margin-bottom: 0.25rem;
    }

    .song-details-container {
      font-size: 12px;
    }

    .main-audio-feature-row,
    .preview-container {
      margin-top: 0;
    }

    .no-preview-text {
      padding-top: 0;
      height: 100%;
      align-items: center;
    }

    &:first-of-type {
      margin-top: 1rem;
    }
  }

  .album-cover {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    padding: 10px;
  }

  h1 {
    font-size: large;
    margin: 0 auto;
    font-weight: bold;
  }

  p {
    margin: 0 auto;
    font-weight: light;
  }

  ion-text.sub {
    margin: 0 auto;
    padding: 0 auto;
  }

  .track-details {
    width: 100%;
    padding-top: 10px;
  }

  .track-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #999aa146;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .track-number::before {
    content: var(--track-order, var(--track-num));
  }

  .track-number-container {
    #drag-handle,
    #delete-track-icon {
      font-size: 1.7em;
    }

    #delete-track-icon {
      font-size: 1.5em;
      padding-left: 0.1em;
      margin-top: 0.1em;
    }
  }

  .actions {
    .dropdown-button-container {
      display: flex;
      justify-content: flex-end;
      font-weight: normal;

      .dropdown-button-expander {
        text-transform: none;
        font-size: 16px;
        font-weight: normal;

        .dropdown-expand-carrot {
          color: var(--ion-color-medium);
          font-size: 0.9em;
        }

        .dropdown-collapse-carrot {
          color: var(--ion-color-primary);
          font-size: 0.9em;
        }
      }
    }
  }

  ion-select {
    --placeholder-color: #ca691c;
    --placeholder-opacity: 1;
    width: 100%;
    justify-content: center;
  }

  .mini-pool {
    margin: 0 auto;
    min-height: unset;
  }

  .matched-track-checkbox {
    padding-left: 15px;
  }

  .matched-track-checkbox-label {
    padding-left: 10px;
  }

  .music-pools {
    margin-right: 18px;
  }

  .close {
    margin-left: 10px;
  }

  .center-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-container {
    margin-top: 15px;
  }

  #preview-container {
    padding: 20px 5px;
    margin-left: 50px;
    border-radius: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .min-border {
    border: 1px solid #999aa1;
    width: 100px;
    height: 50px;
    border-radius: 20px 0 0 0;
  }

  .max-border {
    border: 1px solid #999aa1;
    width: 300px;
    height: 50px;
    margin: 0 30px;
    border-radius: 20px 0 0 0;
  }

  #small-preview-container {
    border-radius: 10px;
    margin: 10px;
  }

  .audio-feature-container {
    //background-color: var(--ion-color-medium-container);
    //border-radius: 5px;
    //padding: 10px 5px;
    //border: 2px solid var(--ion-color-medium-border);
    display: flex;

    justify-content: center;
    //margin-top: 10px;
    //margin-right: 15px;
  }

  .main-audio-feature-row {
    margin-top: 20px;
    padding-left: 10px;
  }

  .audio-feature-container-small {
    //background-color: var(--ion-color-medium-container);
    //border-radius: 5px;
    padding: 5px 20px;
    //margin-left: 10px;
    margin-top: -4px;
    //border: 2px solid var(--ion-color-medium-border);
    //display: flex;
    justify-content: center;
  }

  .small-preview-container {
    margin-top: -20px;
  }

  .song-details-container {
    display: flex;
    align-items: center;
  }

  .no-preview-text {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }
}
