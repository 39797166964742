.savingSpinner {
  $spinnerContainerWidth: auto;

  position: fixed;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: $spinnerContainerWidth;
  left: 50%;
}
