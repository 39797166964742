ion-content {
  :host {
    background: none;
  }
}
ion-col {
  padding: 0;
}
ion-card {
  &.crate-grid-card {
    height: 370px;

    &.loading {
      text-align: center;

      ion-spinner {
        margin-top: 108px;
      }
    }

    ion-card-subtitle {
      line-height: 1.5em;
    }
  }
}
