.video-popover {
  --background: #1c1c1d !important;
  --height: auto;
  --offset-y: -200px;
  --width: auto;

  .video-container {
    height: auto;
    width: 500px;
    border: 2px solid orange; /* Orange border of 2px */
    display: flex;
    flex-direction: column; // Change to column to stack elements vertically
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    video {
      max-width: 100%;
      max-height: 100%;
    }

    .video-content {
      ol:first-child {
        margin-top: 0;
      }

      ol,
      ul {
        padding-left: 20px;
        padding-right: 20px;

        li {
          padding-top: 8px;
        }
      }
    }
  }
}
