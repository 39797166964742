.reorder-filter-warning-dialog {
  --height: auto;

  &-content {
    padding: 1rem;
  }

  &-buttons {
    justify-content: center;
    padding: 1rem;
  }
}
