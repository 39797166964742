.search-content {
  height: 100%;
  width: 100%;
  z-index: 5;
  position: fixed;
  right: 0;
  background-color: rgba(0, 0, 0, 0.35);
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-image: url('../../../public/assets/background/background.svg');
  background-size: cover;

  ion-card.crate-grid-card {
    height: 300px;
    padding: 10px;
  }

  // Account for sidebar
  @media screen and (min-width: 1200px) {
    width: calc(100% - var(--sidebar-width));
  }
}

#search-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 3em;
  padding: 20px;
}

#close-icon {
  position: fixed;
  top: 50px;
  z-index: 1000;
  right: 50px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.53);
  border-radius: 50%;
  opacity: 80%;
}
