.page-header {
  margin-bottom: 20px;
}

.page-header .title {
  font-size: 28px;
  height: 40px;
  font-weight: 600;
  line-height: 40px;
}

.page-header .subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
}

.step-header {
  padding: 12px 25px;
  text-align: center;
}
