.input {
  --background: #170f04;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid {
  width: 100%;
  margin: 0 auto; // Center the grid horizontally
  padding: 1rem;
}

.header-row {
  text-align: center;
  margin-bottom: 2rem;
}

.form-row {
  justify-content: center;
}

.submit-button {
  max-width: 300px;
  width: 100%;
  margin: 1rem auto 0; // Center the button horizontally and add margin-top
  display: block; // Ensure the button is centered
}

.form-field {
  margin-bottom: 1.25rem; // Add space between form fields
}
