.crate-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  //background: rgba(229, 125, 43, 0.2);
  border: 1px solid #47270b;
  margin-bottom: 10px;
  text-decoration: none !important;
  background-color: #211303;
}

.crate-option-text {
  margin: 0;
  padding: 0 13px;
  color: white;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}

.crate-option:hover {
  cursor: pointer;
  background: rgba(229, 125, 43, 0.6);
  > .crate-option-icon {
    color: #fff;
  }
}

.crate-option-icon {
  color: #e57d2b;
  font-size: 1.5rem;
  padding: 0 8px;
}

.crate-option-active,
.crate-option-active:hover {
  background: #ca691d;
  color: white;
}

.crate-option-active .crate-option-icon {
  color: white;
}
