.waveform-container {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  ion-icon {
    background: linear-gradient(
      90deg,
      rgba(211, 119, 28, 1) 35%,
      rgba(248, 158, 35, 1) 100%
    );
    color: #ffffff;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
    font-size: 0.8em;
  }
}

.waveform {
  width: 100%;
}

.waveform.loading {
  position: relative;
}

.loading-indicator {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.flat-line {
  width: 100%;
  height: 2px; // Adjust according to your design
  background-color: #ccc; // Color of the flat line
}

.persistent-player {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  ion-icon {
    background: linear-gradient(
      90deg,
      rgba(211, 119, 28, 1) 35%,
      rgba(248, 158, 35, 1) 100%
    );
    color: #ffffff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.2em;
  }
}
