/* ProgressIndicator.module.css */

.progressIndicator {
  display: flex;
  //justify-content: center;
  align-items: center;
  padding: 10px 20px;
  justify-content: center;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 50px;
}

.stepCircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  color: #9e9e9e;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
}

.circleCompleted {
  background-color: #d3771d;
  color: #fff;
  border-color: #d3771d;
}

.stepLabel {
  margin-top: 8px; /* Space between circle and label */
  font-size: 12px; /* Adjust label font size as needed */
  color: #bebdbd; /* Label text color */
}

.stepContainer:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 30%; /* Center vertically */
  left: 100%; /* Start from the right edge of the circle */
  height: 2px;
  width: 70px; /* Length of the line - adjust based on your layout */
  background-color: #ccc;
  z-index: -1;
}

.stepContainer:not(:first-child)::before {
  content: '';
  position: absolute;
  top: 30%; /* Center vertically */
  right: 100%; /* Start from the left edge of the circle */
  height: 2px;
  width: 90px; /* Length of the line - adjust based on your layout */
  background-color: #ccc;
  z-index: -1;
}

.circleCompleted::after,
.circleCompleted + .stepContainer::before {
  background-color: #d3771d;
}
