@use 'breakpoints' as breakpoints;

#crate-page {
  --crate-filter-height: 108px;

  @media (max-width: breakpoints.$medium-breakpoint) {
    --crate-filter-height: 100px;
  }
}

.crate-card {
  transition: all 1s ease-in-out;

  &.filter-active {
    padding-top: var(--crate-filter-height);
  }

  &.sticky {
    position: sticky;
    top: -40px;
    z-index: 100000;

    &.img {
      top: -20px;
    }
  }

  .categories {
    ion-router-link {
      --color: #cfcfcf;
    }
  }
}

.no-wrap-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.actions-container {
  transition: top 1s ease-in-out;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 100;

    .crate-actions-row {
      margin-top: -50px;
      padding-top: 75px;
    }
  }
}


ion-popover.crate-image-popover {
  --background: rgba(0, 0, 0, 0.85);
  //--backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 150px;
}

.crate-image-cropper {
  padding: 10px;

  img {
    min-width: 100%;
    min-height: 100%;
    border-radius: 8px;
  }

  #edit-image {
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
}

.crate-card,
.crate-grid,
.crate-grid-mini,
.actions-container {
  h2 {
    padding-top: 10px;
    padding-left: 15px;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  .crate-grid-mini-image {
    width: 100%;
  }

  ion-item {
    --min-height: auto;
  }

  ion-label {
    margin: 0;
  }

  .crate-card-image {
    max-height: 200px;
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: right;
  }

  .crate-actions-row {
    transition:
      padding-top 1s ease-in-out,
      margin-top 1s ease-in-out;

    background-color: var(--ion-color-medium-container);
    padding: 20px 10px;
  }

  .crate-grid-card-image {
    text-align: center;
  }

  .save-to-my-crates,
  .export-crate,
  .export-action-buttons {
    --border-radius: 8px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --background-hover: var(--ion-color-primary);
    --background-hover-opacity: 50%;
  }

  .show-track-matcher-toggle {
    padding-top: 20px;
    padding-left: 10px;
  }

  ion-toggle {
    --track-background: var(--ion-color-light);
    --track-background-checked: var(--ion-color-primary);

    --handle-background: grey;
    --handle-background-checked: white;

    --handle-box-shadow: none;
  }

  .custom-segment {
    display: flex;
    justify-content: flex-end; /* Aligns the segment to the right */
    /* Style the buttons to fit the text */
    ion-segment-button {
      min-width: auto; /* Shrinks to fit the content */
      max-width: fit-content; /* Grows only as much as the content needs */
      color: white;
    }
  }

  .magic-sort-row {
    .magic-sort-col {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      ion-range {
        width: 70%;
      }

      ion-label {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .magic-sort-disable {
    justify-content: center;
  }

  ion-toggle::part(track) {
    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }

  .categories {
    color: #b3b3b3;
    font-size: 16px;
  }

  ion-item {
    --border-style: none;
  }

  .hackers {
    ion-router-link {
      color: #d3771d;
    }
  }

  .cloud-crate-grid {
    font-size: 2.5em;

    .cloud-crates-icon {
      font-size: 2.5em;
    }
  }
}

//
//.box {
//  position: relative;
//  width: 100%
//}
//
//
//.box .card {
//  background-color: #ed82161a;
//  border-radius: 12px;
//  height: 280px;
//
//  border: #d3771d thin solid;
//
//  width: 260px;
//}
//
//.box .text {
//  height: 78px;
//  left: 13px;
//  position: absolute;
//  top: 174px;
//  width: 236px;
//}
//
//.box .text-wrapper {
//  color: #d4d4d4;
//  font-family: 'Inter-Regular', Helvetica;
//  font-size: 16px;
//  font-weight: 400;
//  height: 78px;
//  left: 0;
//  letter-spacing: 0;
//  line-height: 25.6px;
//  position: absolute;
//  text-align: center;
//  top: 0;
//  width: 234px;
//}
//
//.box .title {
//  height: 30px;
//
//  position: absolute;
//  top: 125px;
//  width: 51px;
//}
//
//.box .div {
//  color: #ffffff;
//  font-family: 'Mulish-Bold', Helvetica;
//  font-size: 20px;
//  font-weight: 700;
//  height: 30px;
//  left: 0;
//  letter-spacing: 0;
//  line-height: 30px;
//  position: absolute;
//  top: 0;
//  white-space: nowrap;
//}
//
//.box .icon {
//  height: 52px;
//  left: 104px;
//  position: absolute;
//  top: 54px;
//  width: 52px;
//}
//
//.box .all-cards {
//  height: 280px;
//}
//
//.box .frame {
//  align-items: flex-start;
//  display: inline-flex;
//  //gap: 35px;
//}
//
//.header {
//  width: 300px;
//  color: #ffffff;
//  font-family: 'Inter-SemiBold', Helvetica;
//  font-size: 20px;
//  font-weight: 600;
//  left: 0;
//  letter-spacing: 0;
//
//  white-space: nowrap;
//}
//
//.box .card-yt {
//  background-color: #ed82161a;
//  // border: #d3771d thin solid;
//  border-radius: 12px;
//  height: 200px;
//  width: 360px;
//}
//
//.box .card-yt img {
//  height: 194px;
//  width: 354px;
//  border-radius: 12px;
//
//}
//
//.sub-title {
//  font-family: 'Inter-SemiBold', Helvetica;
//  font-size: 20px;
//  font-weight: 600;
//  width: 260px;
//  text-align: center;
//}
//
//.group-icon {
//  position: absolute;
//  left: 116px;
//  top: 49px;
//}
.card {
  background-color: #ed82161a;
  border-radius: 12px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  height: 200px;
  transition: transform 0.3s ease;
}

.card-yt {
  background-color: #ed82161a;
  border-radius: 12px;
  margin: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover,
.card-yt:hover {
  transform: scale(1.05);
}

.overlap-group {
  position: relative;
}

.title {
  margin-top: 10px;
  font-family: 'Inter-SemiBold', Helvetica;
  font-size: 20px;
  font-weight: 600;
}

.text-wrapper {
  margin-top: 10px;
  color: #d4d4d4;
  font-family: 'Inter-Regular', Helvetica;
  font-size: 16px;
}

.header p {
  color: #ffffff;
  font-family: 'Inter-SemiBold', Helvetica;
  font-size: 20px;
  font-weight: 600;
}

.card-yt img {
  width: 100%;
  border-radius: 12px;
}

.condensed-crate-ion-col-condensed {
  padding: 0 !important; /* Remove padding inside IonCol */
  margin: 0 !important; /* Remove any extra margin */
  max-width: 60px !important; /* Set fixed width to match the image */
  flex: 0 0 60px !important; /* Ensure the column takes up the exact width */
}

.condensed-crate-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 60px; /* Set a max width */
  max-height: 60px; /* Set a max height */
  margin-top: 10px;
}

.condensed-crate-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.condensed-crate-title,
.condensed-crate-hacker {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

.condensed-crate-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 4px;
}

.condensed-crate-hacker {
  font-size: 0.9rem;
  color: #888;
}

.crate-grid-condensed,
.crate-grid-condensed-grid {
  width: 100%;
}

.crate-grid-condensed {
  .toggle-container {
    text-align: right;
    margin-bottom: 10px;
    margin-right: 30px;
  }

  .show-more-text {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.1rem;
    color: var(--ion-color-primary);
  }
}

.condensed-crate-container {
  display: flex;
  align-items: center;
  width: 95%;
  margin-bottom: 8px;
  background-color: rgba(237, 130, 22, 0.09);
  border-radius: 12px;
  padding: 3px;
  text-align: center;
}
