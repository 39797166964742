@use 'breakpoints' as breakpoints;

.sort-button {
  @media (max-width: breakpoints.$medium-breakpoint) {
    flex: 0; // Allows text in button to wrap on small screens
  }
}

.sort-button-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.help-icon {
  font-size: 20px;
  color: var(--ion-color-medium);
  cursor: help;
}