ion-modal.update-modal {
  --height: 200px;

  .update-ready,
  .update-progress {
    text-align: center;

    .update-message {
      font-size: 18px;
    }

    .restart-button {
      width: 50%;
      margin: 20px auto 0;
      cursor: pointer;
    }

    .progress-bar {
      width: 75%;
      margin: 20px auto 0;
    }
  }
}
